<template>
    <router-view/>
    <div v-if="this.$route.path !== '/login' && this.$route.path !== '/register/trainee' && this.$route.path !== '/register/trainer' && this.$route.path !== '/forgot/password' && this.$route.path !== '/register/company/trainer'">
      <Footer />
    </div>
  
</template>
<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style>


</style>
