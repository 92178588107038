<template>
    <div class="single-course-inner">
        <div class="thumb">
            <div v-if="course.cover_photo">
                <img v-bind:src="'https://apitraining.vipawaworks.com/' + course.cover_photo" class="avatar float-md-left avatar-medium shadow me-md-4" alt="">
            </div>
            <div v-else>
            <img src="@/assets/img/courses/1.jpg" alt="img">
            </div>
            <div class="course-tag">
                <router-link :to="{ name: 'FilterCategories', params: {id: course.course_category_id}}" class="bg-red">{{ course.category_name }}</router-link>
            </div>
        </div>
        <div class="details">
            <div class="details-inner">
                <h6><router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">
                    {{ title }}
                </router-link></h6>
            </div>
            <div class="emt-course-meta">
                <div class="row">
                    <div class="col-6">
                        <div class="price">
                            <span>Free</span>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <a class="read-more-text" href="#" @click="learnCourse(course.id)"> Lean Now 
                            <svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    components: {  },
    props: ['course'],
    data(){
        return {
            isLoading: true,
            rating: 4
        }
    },
    computed: {
        title: function(){
            if(this.course.title.length > 27) {
                return this.course.title.slice(0,23)+ '...'
            }
            return this.course.title;
        }
    }, 
    methods:{
        learnCourse(id){
        axios.post('https://apitraining.vipawaworks.com/api/course/take_course/' + id).then(response => {
            response.data
            this.$router.push({ name: 'CourseLearn', params: {id: id} })
        }).catch(errors => {
            this.errors = errors.response.data.errors
            if (errors.response.status === 401) {
            localStorage.removeItem('user_data')
            this.$router.push({ name: 'Login'})
            }
        })
        },
   }
}
</script>

<style scoped>
    .single-course-inner {
        border-radius: 7px;
        border: 1px solid #F1F1F1;
        margin-bottom: 30px;
        position: relative;
        transition: 0.4s;
    }
    * {
        box-sizing: border-box;
        outline: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    .single-course-inner .thumb {
        position: relative;
        overflow: hidden;
        border-radius: 7px 7px 0 0;
    }
    .single-course-inner .thumb img {
        transform: scale(1);
        transition: 0.9s;
    }
    img {
        max-width: 100%;
    }
    .single-course-inner .thumb .course-tag {
        position: absolute;
        left: 20px;
        top: 20px;
    }
    .single-course-inner .thumb .course-tag a {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 13px;
        color: #ffffff;
        padding: 0 10px;
        border-radius: 4px;
    }
    .bg-red {
        background: #F14D5D;
    }
    .single-course-inner .details .details-inner {
        padding: 20px 20px 16px;
    }
    .single-course-inner .emt-course-meta .rating {
        color: var(--main-color);
    }
    .single-course-inner .details .details-inner h6 {
        font-weight: 500;
        font-size: 18px;
    }
    .single-course-inner .details .emt-user {
        margin-bottom: 13px;
    }
    .single-course-inner .details .emt-user .u-thumb {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: rgba(253, 200, 0, 0.2);
        display: inline-block;
        margin-right: 7px;
        box-shadow: 0px 1px 10px #c1bfbf;
        border: 2px solid rgba(255, 255, 255, 0.01);
    }
    .single-course-inner .details .emt-user span {
        font-size: 14px;
        color: var(--heading-color);
        font-weight: 500;
    }
    .single-course-inner .emt-course-meta {
        border-top: 1px solid #F1F1F1;
        padding: 10px 20px;
    }
    .single-course-inner .emt-course-meta .price {
        color: rgba(13, 16, 52, 0.5);
        font-weight: 500;
    }
    a, a:hover, a:focus, a:active {
        text-decoration: none;
        outline: none;
        color: inherit;
    }
    img {
        vertical-align: middle;
        border-style: none;
    }
    img {
        max-width: 100%;
    }
</style>