import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state(){
    return {
      token: localStorage.getItem('user_data'),
      trainneLesson: {},
    }
  },
  mutations: {
    lessonContent(state, ){
      state.trainneLesson 
    }
  },
  actions: {
    getLesson(state, id){
      axios.get('https://apitraining.vipawaworks.com/api/lesson/profile/' + id).then(response => {
        state.trainneLesson = response.data
        console.log(state.trainneLesson);
      }).catch(errors => {
          this.errors = errors.response.data.errors
      })
  },
  getUser(){
    axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
    }).catch(errors => {
        if (errors.response.status === 401) {
        localStorage.removeItem('user_data')
        this.$router.push({ name: 'Login'})
        }
    })
},
  },
  modules: {
  }
})
