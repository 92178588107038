<template>
    <div v-if="!isLoading">
        <div  class="row ms-2 me-2 mt-2">
            <div class="col-md-4 mt-2 mb-2" v-for="category in categories" :key="category.id">
                <router-link :to="{ name: 'FilterCategories', params: {id: category.id}}">
                    <div class="card">
                        <img :src="'https://apitraining.vipawaworks.com/images/categories/' + category.image" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h6 class="text-center single-category-title">{{ category.name }}</h6>
                    </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
export default {
    components: { 
        Loading
    },
    data(){
        return{
            isLoading: true,
            errors: {},
            categories: {},
        }
    },
    methods:{
        getCategories(){
            axios.get('https://apitraining.vipawaworks.com/api/course/get_all_categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                this.isLoading =  false
                }.bind(this), 500);
            })
        },
    },
    created(){
        this.getCategories()
    }
}
</script>

<style>

</style>