<template>
<Navbar :user="user" />
    <Slider />
   <section class="courses-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-left">
          <div class="row">
            <div class="col-md-6">
              <div class="card text-white bg-success mb-3" style="height:250px">
                <div class="card-body">
                  <h5 class="card-title text-white">Certifcation Courses</h5>
                  <div class="row">
                    <div class="col-10">
                      <p class="card-text text-white">As a multinational IT Company Engaging in a constant innovation and improvement of its products, we are aware of the necessity that all our distributors are continuously updates as much in the commercial as in techinical aspect</p>
                    </div>
                    <div class="col-2">
                      <svg height="70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>
                    </div>
                  <div class=" pt-2">
                    <router-link :to="{ name: 'Courses'}" class="btn btn-outline-light"><span class="text-white">Read More</span> </router-link>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card text-white bg-secondary mb-3" style="height:250px">
                <div class="card-body">
                  <h5 class="card-title text-white">Employee Training Courses</h5>
                  <div class="row">
                    <div class="col-10">
                      <p class="card-text text-white">If you are a ZKTeco Distributor, contact our team for any support you need. Alter, send us an email or sign-up contact form, we want to solve your problem</p>
                    </div>
                    <div class="col-2 text-center">
                      <div class="text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" height="70" fill="currentColor" class="bi bi-easel-fill" viewBox="0 0 16 16"><path d="M8.473.337a.5.5 0 0 0-.946 0L6.954 2H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h1.85l-1.323 3.837a.5.5 0 1 0 .946.326L4.908 11H7.5v2.5a.5.5 0 0 0 1 0V11h2.592l1.435 4.163a.5.5 0 0 0 .946-.326L12.15 11H14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H9.046L8.473.337z"/></svg>
                      </div>
                    </div>
                  </div>
                  
                  <div class="pt-2">
                    <router-link :to="{ name: 'Courses'}" class="btn btn-outline-light"><span class="text-white">Read More</span> </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-lg pt-5">
          <div class="row justify-content-around">
              <div class="col-md-2">
                <h3 class="btn" @click="switchCategory()">
                  <span v-if="selectedCategory" class="text-success">
                    Categories Filter
                    <div class="progress" style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                  <span v-else>
                    Categories Filter
                    <div class="progress"  style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                      <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> 
                  </span>
                </h3>
              </div>
              <div class="col-md-2">
                <h3 class="btn" @click="switchSelfTest()">
                  <span v-if="selectedSelfTest" class="text-success">
                    Freeselft Test
                    <div class="progress" style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                  <span v-else>
                    Freeselft Test
                    <div class="progress"  style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                      <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> 
                  </span>
                </h3>
                
              </div>
              <div class="col-md-3">
                <h3 class="btn" @click="switchRecently()">
                  <span v-if="selectedRecently" class="text-success">
                    Recently Courses
                    <div class="progress" style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                  <span v-else>
                    Recently Courses
                    <div class="progress"  style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                      <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                </h3>
              </div>
              <div class="col-md-2">
                <h3 class="btn" @click="switchMostPopular()">
                  <span v-if="selectedPopular" class="text-success">
                    Most Popular
                    <div class="progress" style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                  <span v-else>
                    Most Popular
                    <div class="progress"  style="height: 8px;">
                      <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                      <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </span>
                </h3>
            </div>
            </div>
          <div class="section-title pt-5">
            <div v-if="selectedCategory">
              <Categories />
            </div>
            <div v-else-if="selectedSelfTest">
              <SelfTests />
            </div>
            <div v-else-if="selectedRecently">
              <RecentlyCourses />
            </div>
            <div  v-else-if="selectedPopular">
              <PopularCourses />
            </div>
          </div>
        </div>
        <div class="section-title pt-5">
            <h3 class="text-start"><span class="text-green">|</span> Coorperative Clients</h3>
            <div class="row pt-3">
              <div class="col-md-2">
                <img src="@/assets/img/client/airtel.jpg" class="card-img-top client-img" alt="...">
              </div>
              <div class="col-md-2">
                <img src="@/assets/img/client/carcare.jpg" class="card-img-top client-img" alt="...">
              </div>
              <div class="col-md-2">
                <img src="@/assets/img/client/fnb.jpg" class="card-img-top client-img" alt="...">
              </div>
              <div class="col-md-2">
                <img src="@/assets/img/client/huawei.jpg" class="card-img-top client-img" alt="...">
              </div>
              <div class="col-md-2">
                <img src="@/assets/img/client/SUN.png" class="card-img-top client-img" alt="...">
              </div>
            </div>
          </div>
      </div>
    </section>
    <!-- <Instructor /> praktica uni sans --> 
  
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Slider from '@/components/Slider.vue'
// import Loading from '@/components/Loading.vue'
import axios from 'axios'
import Categories from '@/components/home/Categories.vue'
import SelfTests from '@/components/home/SelfTests.vue'
import RecentlyCourses from '@/components/home/RecentlyCourses.vue'
import PopularCourses from '@/components/home/PopularCourses.vue'
// import Instructor from '@/components/Instructor.vue'
export default {
  name: 'Home',
  components: {
    Slider, Navbar,Categories,SelfTests,RecentlyCourses,PopularCourses,
    // SingleCourse,Instructor,
  },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      errors: {},
      courses: {},
      user:{},
      authError: null,
      selectedCategory: true,
      selectedSelfTest: false,
      selectedPopular: false,
      selectedRecently: false
    }
  },
  methods:{
    switchCategory(){
      this.selectedCategory = true,
      this.selectedSelfTest = false,
      this.selectedPopular = false,
      this.selectedRecently = false
    },
    switchSelfTest(){
      this.selectedSelfTest = true,
      this.selectedCategory = false,
      this.selectedPopular = false,
      this.selectedRecently = false
    },
    switchRecently(){
      this.selectedRecently = true,
      this.selectedCategory = false,
      this.selectedSelfTest = false,
      this.selectedPopular = false
    },
    switchMostPopular(){
      this.selectedPopular = true,
      this.selectedCategory = false,
      this.selectedSelfTest = false,
      this.selectedRecently = false

    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
    document.title = 'Home - Training'
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser()

  }
}
</script>
<style scoped>
  
</style>
