<template>
  <header class="header-area p-relative">
    <div class="top-header top-header-style-four">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-8">
            <ul class="top-header-contact-info">
              <li>
                Call:
                <a href="tel:+255717443616">+255 (717) 443616</a>
              </li>
            </ul>
            <div class="top-header-social">
              <span>Follow us:</span>
              <a target="_blank" href="https://web.facebook.com/Vipawaworks-100433252263696" class="rounded text-white px-2 fs-5" >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social">
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
              </a>
              <a target="_blank" href="https://www.instagram.com/vipawaworks/" class="rounded text-white px-2 fs-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </a>
              <a target="_blank" href="https://twitter.com/vipawaworks" class="rounded text-white px-2 fs-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social">
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/vipaji-jobs/" class="rounded text-white px-2 fs-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav id="topnav"  class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <router-link :to="{ name: 'Home' }" class="navbar-brand">
          <img src="@/assets/img/logo.png" class="logo" alt="logo" />
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
            <li class="nav-item dropdown">
               <a class="dropdown-toggle  me-2" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Courses Categories</a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li v-for="category in categories" :key="category.id">
                  <router-link :to="{ name: 'FilterCategories', params: {id: category.id}}"  class="btn dropdown-item">{{ category.name }}</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item me-2">
              <router-link :to="{ name: 'SelfTests' }">
                Self Tests
              </router-link>
            </li>
          </ul>
          <div class="form-75 dropdown-search">
            <input class="form-control me-2  gx-5 dropdown-input" type="search" placeholder="Search for course title or author name" aria-label="Search"  v-model="search">
            <div class="dropdown-list">
              <ul class="list-group" v-for="course in matchingCourses" :key="course.id">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <router-link :to="{ name: 'CourseDetails', params: {id: course.id}}" class="ms-2 me-auto">
                      <div class="fw-bold">{{ course.title }}</div>
                      <div class="text-dark">
                       Author {{ course.user_name}}
                      </div>
                    </router-link>
                </li>
              </ul>
            </div>
          </div>
          <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
            <li class="nav-item" v-if="token">
              <router-link :to="{ name: 'TrainerRegister' }" class="btn" v-if="user.status == 1">
                Be a Trainer
              </router-link>
            </li>
            <li class="nav-item" v-if="token">
              <router-link :to="{ name: 'TrainerDashboard' }" class="btn">
                Dashboard
              </router-link>
            </li>

            <li class="nav-item dropdown" v-if="token">
              <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <div v-if="user.avatar">
                    <img v-bind:src="'https://apitraining.vipawaworks.com/' + user.avatar" class="rounded-circle mr-2 img-50" alt="">
                </div>
                <div v-else>
                    <img src="@/assets/img/team/1.jpg" class="rounded-circle mr-2 img-50" alt="">
                </div>
              </a>
              <ul class="dropdown-menu bg-default-color" aria-labelledby="dropdownMenuLink">
                <li class="nav-item">
                  <a @click="logout" class="btn default-btn dropdown-item">
                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                    </svg>
                    Logout
                  </a>
                </li>
              </ul>
            </li>
              <li class="nav-item" v-if="!token">
                <router-link :to="{ name: 'Login' }" class="btn">
                  Login
                </router-link>
              </li>
              <li class="nav-item" v-if="!token">
                <router-link :to="{ name: 'TraineeRegister' }" class="btn">
                  Register
                </router-link>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import axios from 'axios'
export default {
  props:['user'],
  data(){
    return{
      token:false,
      courses:{},
      categories: {},
      sub_categories: {},
      subjects: {},
      search:'',
    }
  },
   computed: {
    matchingCourses: function(){
      var self = this
      if(this.search) {
          return this.courses.filter(function(courseFilter){
            return ((courseFilter.title.toLowerCase().indexOf(self.search.toLowerCase()) !== -1) || (courseFilter.user_name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1))
        })
      }else{
        return {}
      }

    }
   },
   methods: {
    getToken(){
      return this.token = localStorage.getItem('user_data')
    },
    getCategories(){

      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_categories').then(response => {
        this.categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getCourses(){

      axios.get('https://apitraining.vipawaworks.com/api/course/courses').then(response => {
        this.courses = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    logout() {
    axios.post('https://apitraining.vipawaworks.com/api/auth/user/logout').then(response => {
        response
        localStorage.removeItem('user_data')
        this.$router.push({ name: 'Login'})
        }).catch(errors => {
        if (errors) {
            localStorage.removeItem('user_data')
            this.$router.push({ name: 'Login'})
        }
        })
    },
  },
  created(){
    this.getToken()
    this.getCategories()
    this.getCourses()
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", function () {
        var navbar = document.getElementById("topnav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 5) {
          if (nav_classes.contains("fixed-top") === false) {
            nav_classes.toggle("fixed-top");
          }
        } else {
          if (nav_classes.contains("fixed-top") === true) {
            nav_classes.toggle("fixed-top");
          }
        }
      });
    });
  },
};
</script>

<style scoped>

</style>

