import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {Tabs, Tab} from 'vue3-tabs-component'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/bootstrap.min.css'
import './assets/revolution/css/layers.css'
import './assets/css/flaticon.css'
import './assets/css/meanmenu.min.css'
import './assets/css/nice-select.min.css'
import './assets/css/slick.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/custom.css'

createApp(App).use(store).use(router).component('tabs', Tabs)
.component('tab', Tab).mount('#app')
