<template>
  <div class="banner-section">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-8 bg-transparent left-side card text-white mb-30 shadow-lg me-5">
                  <div class="bg-overlay rounded-3"></div>
                  <div class="vertical-center">
                    <div class="card-body">
                      <div class="member-content ">
                        <p class="text-white praktica fs-6"> Vipawa Training have online tests designed to accurately and fairly assess your candidates.</p>
                      <p class="text-white praktica fs-6"> They use robust modern performance algorithms to guard against candidate cheating.
                        Their tests present an excellent candidate experience and the questions are designed to cover all ranges of difficulty</p>
                        <h5 class="text-white unisans">ENGAGING CANDIDATE <span class="bg-success">ASSESSMENTS</span> WHICH PREDICT JOB <span class="bg-success">PERFORMANCES</span></h5>
                        <router-link :to="{ name: 'Courses'}" class="btn btn-outline-success"><span class="text-white">See More</span> </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 pt-2 d-none d-sm-none d-md-none d-lg-block">
                  <div class="row justify-content-center">
                    <div class="col-md-2 bg-transparent card text-white mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
                              <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                              <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Verbal Reasoning</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent text-white mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                              <svg height="60" xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                              </svg>
                          </div>
                          <div class="member-content  d-none d-lg-block">
                            <p class="text-white f-12">Mechanical Reasoning</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent text-white mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-easel2" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 0a.5.5 0 0 1 .447.276L8.81 1h4.69A1.5 1.5 0 0 1 15 2.5V11h.5a.5.5 0 0 1 0 1h-2.86l.845 3.379a.5.5 0 0 1-.97.242L12.11 14H3.89l-.405 1.621a.5.5 0 0 1-.97-.242L3.36 12H.5a.5.5 0 0 1 0-1H1V2.5A1.5 1.5 0 0 1 2.5 1h4.691l.362-.724A.5.5 0 0 1 8 0ZM2 11h12V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5V11Zm9.61 1H4.39l-.25 1h7.72l-.25-1Z"/>
                              </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Dramatic Reasoning</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent text-white mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
                            </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Critical Thinking</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-md-2 card bg-transparent mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                              <svg height="60" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Asessment Center</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-diagram-3" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                            </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Logical Reasoning</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                      <div class="vertical-center">
                        <div class="card-body">
                          <div class="member-image text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-bezier" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                              <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z"/>
                            </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Situation Judgement</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 card bg-transparent mb-30 shadow-lg me-2 rounded-3">
                      <div class="bg-overlay rounded-3"></div>
                        <div class="vertical-center rounded-3">
                        <div class="card-body">
                          <div class="member-image text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                          </div>
                          <div class="member-content d-none d-lg-block">
                            <p class="text-white f-12">Personality Tests</p>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                  
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

  .left-side{
    height: 500px;
  }
  .vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .bg-overlay {
    opacity: 0.5;
    background-color: #474b4f;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .unisans{
    font-family: uni-sans;
  }
  .praktica{
    font-family: praktica;
  }
  .f-12{
    font-size: 11px !important;
  }
</style>