import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',name: 'Home', component: Home
  },
  {
    path: '/about', name: 'About', component: () => import('@/views/About.vue')
  },
  {
    path: '/contact', name: 'Contact', component: () => import('@/views/Contact.vue')
  },
  {
    path: '/feedback', name: 'Feedback', component: () => import('@/views/Feedback.vue')
  },
  {
    path: '/login', name: 'Login', component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/register/trainee', name: 'TraineeRegister', component: () => import('@/views/auth/Register.vue')
  },
  {
    path: '/register/trainer', name: 'TrainerRegister', component: () => import('@/views/auth/TrainerRegister.vue')
  },
  {
    path: '/register/company/trainer', name: 'TrainerRegisterCompany', component: () => import('@/views/auth/CompanyTrainer.vue')
  },
  {
    path: '/forgot/password', name: 'ForgetPassword', component: () => import('@/views/auth/ForgetPassword.vue')
  },
  //auth routes ends

  //courses routes starts
  {
    path: '/courses', name: 'Courses', component: () => import('@/views/courses/Index.vue')
  },
  {
    path: '/courses/categories/:id', name: 'FilterCategories', component: () => import('@/views/CourseFilters/Categories.vue')
  },
  {
    path: '/courses/author/:id', name: 'FilterByAuthors', component: () => import('@/views/CourseFilters/Author.vue')
  },
  {
    path: '/courses/:id',  name: 'CourseDetails', component: () => import('@/views/courses/Details.vue')
  },
  {
    path: '/courses/completed/:id',  name: 'CourseCompleted', component: () => import('@/views/courses/Completed.vue')
  },
  //course routes ends

  //course chapters and lessons routes starts
  {
    path: '/courses/chapters/:id',  name: 'CourseLearn', component: () => import('@/views/courses/Learn.vue')
  },
  {
    path: '/courses/chapters/lesson/:id',  name: 'CourseLesson', component: () => import('@/views/courses/LessonDetails.vue')
  },
  // course chapters and lessons routes ends

  // course quiz and lessons starts
  {
    path: '/courses/quiz/lesson/:id',  name: 'CourseQuiz', component: () => import('@/views/courses/quiz/Index.vue')
  },
  {
    path: '/courses/quiz/lesson/details/:id',  name: 'CourseQuizDetails', component: () => import('@/views/courses/quiz/Details.vue')
  },
  {
    path: '/courses/lesson/quiz/questions/:id',  name: 'CourseQuizQuestion', component: () => import('@/views/courses/quiz/Questions.vue')
  },
  {
    path: '/courses/lesson/quiz/results/:id',  name: 'CourseQuizResults', component: () => import('@/views/courses/quiz/Results.vue')
  },
  //course quiz and lessons ends

  //
  {
    path: '/tests', name: 'Tests', component: () => import('@/views/courses/test/Index.vue')
  },
  {
    path: '/tests/:id',  name: 'TestCouresDetails', component: () => import('@/views/courses/test/Details.vue')
  },
  {
    path: '/tests/question/:id',  name: 'TestCourseQuestion', component: () => import('@/views/courses/test/Questions.vue')
  },
  {
    path: '/tests/results/:id',  name: 'TestCourseResults', component: () => import('@/views/courses/test/Results.vue')
  },

  //self test starts
  {
    path: '/selftests', name: 'SelfTests', component: () => import('@/views/tests/Index.vue')
  },
  {
    path: '/selftests/:id',  name: 'SelfTestsDetails', component: () => import('@/views/tests/Details.vue')
  },
  {
    path: '/selftests/question/:id',  name: 'SelfTestsQuestion', component: () => import('@/views/tests/Questions.vue')
  },
  {
    path: '/selftests/results/:id',  name: 'SelfTestsResults', component: () => import('@/views/tests/Results.vue')
  },
 //self test ends

  //account routes starts
  {
    path: '/dashbaord',  name: 'TrainerDashboard', component: () => import('@/views/trainer/Dashboard.vue')
  },
  {
    path: '/vipawaworkscv',  name: 'TrainerCV', component: () => import('@/views/trainer/cv/Index.vue')
  },
  {
    path: '/profile',  name: 'TrainerProfile', component: () => import('@/views/trainer/profile/Index.vue')
  },
  {
    path: '/profile/edit',  name: 'TrainerEditProfile', component: () => import('@/views/trainer/profile/Edit.vue')
  },
  {
    path: '/profile/password',  name: 'TrainerPasswordProfile', component: () => import('@/views/trainer/profile/Password.vue')
  },
  //account routes end

  //trainee corses
  {
    path: '/mycourses',  name: 'TraineeCourses', component: () => import('@/views/trainer/mycourse/Index.vue')
  },

  //courses routes starts
  {
    path: '/trainer/courses',  name: 'TrainerCourses', component: () => import('@/views/trainer/courses/Index.vue')
  },
  {
    path: '/trainer/courses/add',  name: 'TrainerAddCourses', component: () => import('@/views/trainer/courses/Add.vue')
  },
  {
    path: '/courses/dashboard/:id',  name: 'CourseDashboard', component: () => import('@/views/trainer/courses/Dashboard.vue')
  },
  {
    path: '/trainer/courses/edit/:id',  name: 'TrainerEditCourses', component: () => import('@/views/trainer/courses/Edit.vue')
  },
  {
    path: '/trainer/courses/description/:id',  name: 'TrainerDescriptionCourses', component: () => import('@/views/trainer/courses/Description.vue')
  },
  {
    path: '/trainer/courses/assignment/:id',  name: 'TrainerCoursesAssigment', component: () => import('@/views/trainer/courses/CourseAssigment.vue')
  },
  //trainer courses end

    //courses chapters start
    {
      path: '/trainer/courses/chapter/add/:id',  name: 'TrainerChapterAdd', component: () => import('@/views/trainer/courses/chapter/Add.vue')
    },
    {
      path: '/trainer/courses/chapter/edit/:id/:chapterId',  name: 'TrainerChapterEdit', component: () => import('@/views/trainer/courses/chapter/Edit.vue')
    },
  //lesson chapters ends

  //courses topic start
  {
    path: '/trainer/courses/lessons/:id',  name: 'TrainerLesson', component: () => import('@/views/trainer/courses/lessons/Index.vue')
  },
  {
    path: '/trainer/courses/lessons/add/:id',  name: 'TrainerLessonAdd', component: () => import('@/views/trainer/courses/lessons/Add.vue')
  },
  {
    path: '/trainer/courses/lessons/edit/:id/:lesson',  name: 'TrainerLessonEdit', component: () => import('@/views/trainer/courses/lessons/Edit.vue')
  },
  //lesson topics ends

  //courses quiz start
  {
    path: '/trainer/courses/quizes/:id',  name: 'TrainerQuizes', component: () => import('@/views/trainer/courses/quizes/Index.vue')
  },
  {
    path: '/trainer/courses/quizes/add/:id',  name: 'TrainerQuizAdd', component: () => import('@/views/trainer/courses/quizes/Add.vue')
  },
  {
    path: '/trainer/courses/quizes/edit/:id/:lesson',  name: 'TrainerQuizEdit', component: () => import('@/views/trainer/courses/quizes/Edit.vue')
  },
  //lesson quiz ends

  //quiz questions start
  {
    path: '/trainer/courses/quizes/questions/add/:id',  name: 'TrainerQuestionAdd', component: () => import('@/views/trainer/courses/quizes/questions/Add.vue')
  },
  {
    path: '/trainer/courses/quizes/questions/details/:id',  name: 'TrainerQuestionDetails', component: () => import('@/views/trainer/courses/quizes/questions/Details.vue')
  },
  {
    path: '/trainer/courses/quizes/questions/edit/:id',  name: 'TrainerQuestionEdit', component: () => import('@/views/trainer/courses/quizes/questions/Edit.vue')
  },
  {
    path: '/trainer/courses/quizes/questions/answer/edit/:id/:question',  name: 'TrainerOptionEdit', component: () => import('@/views/trainer/courses/quizes/questions/EditOption.vue')
  },
  //quiz question ends
  

  // trainer tests start
 
  {
    path: '/trainer/tests/:id',  name: 'TrainerDetailsTest', component: () => import('@/views/trainer/courses/tests/Details.vue')
  },
  {
    path: '/trainer/tests/add/:id',  name: 'TrainerAddTest', component: () => import('@/views/trainer/courses/tests/Add.vue')
  },
  {
    path: '/trainer/tests/edit/:id/:test',  name: 'TrainerEditTest', component: () => import('@/views/trainer/courses/tests/Edit.vue')
  },
  //trainer tests end

   //courses topic start
  {
    path: '/trainer/tests/questions/:id',  name: 'TrainerTestQuestionsDetails', component: () => import('@/views/trainer/courses/tests/questions/Details.vue')
  },
  {
    path: '/trainer/tests/questions/add/:id',  name: 'TrainerTestQuestionsAdd', component: () => import('@/views/trainer/courses/tests/questions/Add.vue')
  },
  {
    path: '/trainer/tests/questions/edit/:id',  name: 'TrainerTestQuestionsEdit', component: () => import('@/views/trainer/courses/tests/questions/Edit.vue')
  },
  {
    path: '/trainer/tests/questions/answer/edit/:id/:question',  name: 'TrainerTestOptionEdit', component: () => import('@/views/trainer/courses/tests/questions/EditOption.vue')
  },
  //lesson topics ends

  //classroom start
  {
    path: '/trainer/classrooms',  name: 'TrainerClassRoom', component: () => import('@/views/trainer/classroom/Index.vue')
  },
  {
    path: '/trainer/classrooms/add',  name: 'TrainerAddClassRoom', component: () => import('@/views/trainer/classroom/Add.vue')
  },
  {
    path: '/trainer/classrooms/edit/:id',  name: 'TrainerEditClassRoom', component: () => import('@/views/trainer/classroom/Edit.vue')
  },
  {
    path: '/classrooms/:id',  name: 'TrainerClassRoomDashboard', component: () => import('@/views/trainer/classroom/Dashboard.vue')
  },
  {
    path: '/classrooms/invitation/:id',  name: 'ClassRoomInvitation', component: () => import('@/views/trainer/classroom/Invitation.vue')
  },
  //classroom ends

  //classroom start
  {
    path: '/trainer/classrooms/trainees/:id',  name: 'TrainerClassRoomTrainee', component: () => import('@/views/trainer/classroom/trainees/Index.vue')
  },

  //classroom ends

  //trainer start
  {
    path: '/classrooms/courses/:id',  name: 'TrainerClassRoomCourses', component: () => import('@/views/trainer/classroom/courses/Index.vue')
  },

  //course ends
  
  // catch all 404 pages 
  {
    path: '/:catchAll(.*)',name: 'NotFound',component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return document.getElementById('app').scrollIntoView();
    }
  }
})


function loggedIn(){
  return localStorage.getItem("user_data")
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
        query: {redirect: to.fullpath}
      })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router
