<template>
  <footer class="footer-area">
      <div class="footer-bottom-area">
        <div class="container">
          <div class="logo">
            <router-link :to="{ name : 'Home' }" class="d-inline-block">
              <img src="@/assets/img/logo.png" class="logo" alt="image"/>
            </router-link>
          </div>
          <p>
            © {{new Date().getFullYear()}}.
            <router-link :to="{ name : 'Home' }" >Vipawa Training</router-link> Designed By
            <a href="https://ikabit.co.tz/" target="_blank">Ikabit</a> | All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>

</template>

<script>
export default {

}
</script>

<style>

</style>