<template>
    <div v-if="!isLoading">
        <div  v-if="courses.length">
          <div class="row ms-2 me-2">
            <div class="col-lg-2 col-md-2 mix business design language" v-for="course in courses" :key="course.id">
              <SingleCourse :course="course" />
            </div>
          </div>
        </div>
        <div v-else>
            <h4 class="text-center">We have No Available Test</h4>
        </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>


<script>
import SingleCourse from '@/components/SingleCourse.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
export default {
  components: { 
     SingleCourse, Loading
  },
  data(){
    return {
      isLoading: true,
      errors: {},
      courses: {}
    }
  },
  methods:{
    getCourses(){
      axios.get('https://apitraining.vipawaworks.com/api/course/courses').then(response => {
        this.courses = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    this.getCourses() 
  }
}
</script>


<style>

</style>